
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexf92vMeFVnFTK_1_45UvP6Slh90iB6qkk4KaiMHMkia2rYMeta } from "/app/services/frontend/src/pages/index.vue?macro=true";
import { default as loginoH5I5W90BlZYOUkT4FT7hkbxqERVQjfVJjl5gAu10r0Meta } from "/app/services/frontend/src/pages/login.vue?macro=true";
import { default as bonusesN5dhYisSXzsRQrfoIMkksDdpg8u_45t0Xpc7MacdeMkDcMeta } from "/app/services/frontend/src/pages/bonuses.vue?macro=true";
import { default as contactcpJvjKWwzPbSQ17PfIS13tbtG4y5lxQvH_451_45PbCwVAoMeta } from "/app/services/frontend/src/pages/contact.vue?macro=true";
import { default as cookiesHOFhRshqKgtPUXYqxWphfGaK9sXhqX3mt_OWYYzFcbkMeta } from "/app/services/frontend/src/pages/cookies.vue?macro=true";
import { default as dpd_45mapHEWxtX640C0eI5sV55u4hwEdUforPHIDVVCdTVtWj6IMeta } from "/app/services/frontend/src/pages/dpd-map.vue?macro=true";
import { default as premiumgyVwM9CoZOb3Z4yCanB4EEVfrUNS5Y9WMrh4FVqithwMeta } from "/app/services/frontend/src/pages/premium.vue?macro=true";
import { default as indexIqDLR_455DM27C7SQc4zqHAOI72iQet6FfkwX7W8UfT90Meta } from "/app/services/frontend/src/pages/profile/index.vue?macro=true";
import { default as bonusesBXypkuLRyCKuvmWGpB5HBXDh7_MiYRiQWeOb9ep4G_45gMeta } from "/app/services/frontend/src/pages/profile/bonuses.vue?macro=true";
import { default as rewards3HEI4LysQwBYPp_45iWSA7kd_45AlOxdcdmvYyN8pnSY_45SEMeta } from "/app/services/frontend/src/pages/profile/rewards.vue?macro=true";
import { default as messagesTeW9pDkyKtPhcQMsl2e1PqKX_45hQSMauh3ER_45rQuurfYMeta } from "/app/services/frontend/src/pages/profile/messages.vue?macro=true";
import { default as dashboardyzkfeBGvQXr301hDYPA4kvQIyHRsZcuw4aoRVvXinP0Meta } from "/app/services/frontend/src/pages/profile/dashboard.vue?macro=true";
import { default as educationLNkSyhmKIdgbP7_45xrw1O0Ngaxusp19UpmRGdNdrCEj0Meta } from "/app/services/frontend/src/pages/profile/education.vue?macro=true";
import { default as my_45buyoutse4FvltYQpjuk_45i68bQMOlN1lRliKYumAlInp8Y3SytQMeta } from "/app/services/frontend/src/pages/profile/my-buyouts.vue?macro=true";
import { default as tariff_45listDtkE_45oKSQI_45Igb7j6TwTtq1_45y57bKNVKqlp01vTBdPoMeta } from "/app/services/frontend/src/pages/profile/tariff-list.vue?macro=true";
import { default as notifications8dByfW_45HO_45y9x1mlPuAnlg6_45tUlqNDRs6cGZc78GhQ4Meta } from "/app/services/frontend/src/pages/profile/notifications.vue?macro=true";
import { default as personal_45info7XeRLV7UocYW81HsiXOVuIyKna3ZmKbcZ39oRt0dfLsMeta } from "/app/services/frontend/src/pages/profile/personal-info.vue?macro=true";
import { default as delivery_45notesx_y84AhJrGzlOJ0NeVPaLZX5LMs9F_45wjLvDGF3rSgxYMeta } from "/app/services/frontend/src/pages/profile/delivery-notes.vue?macro=true";
import { default as my_45buyouts_45on_45store6i_k5FV0WY5YUoHADWiynQRAUZ1cAvJhRtixfw1lLIYMeta } from "/app/services/frontend/src/pages/profile/my-buyouts-on-store.vue?macro=true";
import { default as _91registration_number_93MxZNHl6bF5HIEec_45LjErHt4KNGoc_AnPB2G2AjdB4iwMeta } from "/app/services/frontend/src/pages/profile/buyout-on-store-detail/[registration_number].vue?macro=true";
import { default as profileNcPNWF1B8QDTQtkyb28wuG1PIY1HX_fCkeLnjVimR_YMeta } from "/app/services/frontend/src/pages/profile.vue?macro=true";
import { default as _ssoRFWmB3AGKIsn2wtv1EaPyhDZjqwRnOAMVMsbRPLKmUIMeta } from "/app/services/frontend/src/pages/[partner]/_sso.vue?macro=true";
import { default as based_457mGQGsgA5KkbiINyIKb78Wibpiy1dxHwWqrtPAIosMeta } from "/app/services/frontend/src/pages/[partner]/base.vue?macro=true";
import { default as index2XbkPhgmoRlPhmLSqaP_GFgOHTYqoM2LUMjjDGPUd38Meta } from "/app/services/frontend/src/pages/[partner]/index.vue?macro=true";
import { default as xiaomi8b0syZ_Mw72dN4GnoJEGNkNeOZBlcoKRbVhjprk6DRcMeta } from "/app/services/frontend/src/pages/[partner]/xiaomi.vue?macro=true";
import { default as bonuseseXRhkPSG76_pgahio45iW0V9gEwMh1X5S_XTC0AE_45SYMeta } from "/app/services/frontend/src/pages/[partner]/bonuses.vue?macro=true";
import { default as contactueaeoWxn4EtS_45uAPC9t_45C8EYzxLZYfLtvagD0A8_45M00Meta } from "/app/services/frontend/src/pages/[partner]/contact.vue?macro=true";
import { default as samsungVUDQULIDG_wkwV2ehPAQXeYnJOUibBCUN2AoAXwIxtAMeta } from "/app/services/frontend/src/pages/[partner]/samsung.vue?macro=true";
import { default as partnersGVMHDqd6BvRHu6mg2CF3vqAEWomvZDZsFGw2WGIzZVYMeta } from "/app/services/frontend/src/pages/[partner]/partners.vue?macro=true";
import { default as _sso_45finish2IKm5Etr8JDXCOSbnZGkPxS44jRyK9LGWcVq79Qyk8IMeta } from "/app/services/frontend/src/pages/[partner]/_sso-finish.vue?macro=true";
import { default as samsung_45privacy_45policyo8Udr_45vdwSAUrPCTlINW_UCc9_45kcfIdCxB41naTwIEMMeta } from "/app/services/frontend/src/pages/[partner]/samsung-privacy-policy.vue?macro=true";
import { default as _91partner_93jKXMi6lyFWp28rl5U9C7Cs8G6GmLRctT7JkjkR2pD2MMeta } from "/app/services/frontend/src/pages/[partner].vue?macro=true";
import { default as questionsZCKMf13H0_459s5Z1C27lNAB2AHAb_T7tbZeXWIEIpfwcMeta } from "/app/services/frontend/src/pages/questions.vue?macro=true";
import { default as ssoKpmUZmuJqyb7MXx_ntVGqgvadNKo26ZqT4EmHwiPsG4Meta } from "/app/services/frontend/src/pages/vodafone/sso.vue?macro=true";
import { default as privacy_45policyoidUwg0kMvh4f6h7qGZUfw_8jq2JNb9tZ9WfDRbMfvEMeta } from "/app/services/frontend/src/pages/privacy-policy.vue?macro=true";
import { default as boxGEW6Af8Bda0zXgvCcQpon1vz2qeE1qY_YtnWioacQCMMeta } from "/app/services/frontend/src/pages/sustainability/box.vue?macro=true";
import { default as hiwrInxxRQoMVo_455Zc07ScwdA1AkdWOQP7UQt7UCE7vvjYMeta } from "/app/services/frontend/src/pages/sustainability/hiw.vue?macro=true";
import { default as hwhuwHCgsGzcskVCKZlEen69AW_45xgWszUkaNvpXaZ0CmGMMeta } from "/app/services/frontend/src/pages/sustainability/hwh.vue?macro=true";
import { default as heroDywrm2EJcCF_45VJqod9trfmrqf0LVRJRmIFbTo8IakPMMeta } from "/app/services/frontend/src/pages/sustainability/hero.vue?macro=true";
import { default as info3C7_45Uqx5IHDiBxWoB5ecvLMllKw3rwyIjleXKr4AY3EMeta } from "/app/services/frontend/src/pages/sustainability/info.vue?macro=true";
import { default as keyAreaso4HCDzuPhuSJxBkSOBzpwGRTFv02BCFrLv6Lo3I6um0Meta } from "/app/services/frontend/src/pages/sustainability/keyAreas.vue?macro=true";
import { default as sustainabilityYoUDF_45MUouPs9ZhPPvaPYas8cD16IMpWC6gnxmshBXgMeta } from "/app/services/frontend/src/pages/sustainability.vue?macro=true";
import { default as apiVsU2uQ2xpmM1CBWZA4G3ELcj0PiMkdQMY7QGsYJlPmsMeta } from "/app/services/frontend/src/pages/profile-premium/api.vue?macro=true";
import { default as indexb9VBrrKf7uX0gVz_45iZXiYN0ksNON6jNozxBmUZWiWnsMeta } from "/app/services/frontend/src/pages/profile-premium/index.vue?macro=true";
import { default as exports63noMl5c_JTCp4THqGDF6tFblEiRVGT1o7JYMrwY7o4Meta } from "/app/services/frontend/src/pages/profile-premium/exports.vue?macro=true";
import { default as dashboardjyrQQLLZOCKjPZbI1k6qZBYDZzNOuC2OLaDPBuyxdyMMeta } from "/app/services/frontend/src/pages/profile-premium/dashboard.vue?macro=true";
import { default as educationgpKyYTx_45bK6ds2sn_45Ht6TkuW7mDDicwbcU1FF6msISAMeta } from "/app/services/frontend/src/pages/profile-premium/education.vue?macro=true";
import { default as ambassadorsaFmhMHVZ9cguvbO290yKJNTBvWgN1aMybDJ5ZlltIXgMeta } from "/app/services/frontend/src/pages/profile-premium/ambassadors.vue?macro=true";
import { default as company_45data7oRrga_DYpsCLN7Av5_4542pUmdEXhOafSslzIndrTOhMMeta } from "/app/services/frontend/src/pages/profile-premium/company-data.vue?macro=true";
import { default as notificationssRdkiyLwh3vksVMnsbG2ogUqrE__45XYqvsGDA1P8M4j4Meta } from "/app/services/frontend/src/pages/profile-premium/notifications.vue?macro=true";
import { default as import_45ambassadorsXHqblMSzkYBIq1ZaxYi5diowgOjiVmXbl8tarAt5EvsMeta } from "/app/services/frontend/src/pages/profile-premium/import-ambassadors.vue?macro=true";
import { default as profile_45premiumu7OSnMtcKpckSgkr5TQttqMQsbHSRO4BVx_mN4P7wmkMeta } from "/app/services/frontend/src/pages/profile-premium.vue?macro=true";
import { default as partnerfN41GEpoSM3NqcfsGg0iHW8cNQn_45OYAgiQI6zWOFPJUMeta } from "/app/services/frontend/src/pages/register/partner.vue?macro=true";
import { default as _91id_933eia60zE2NBizOSM_yYItT9M53xUO07hA21lfSX_45fmMMeta } from "/app/services/frontend/src/pages/buyout-detail/[id].vue?macro=true";
import { default as _91id_93rm6vJ1cSqSi5S_fyurZ5J5K4RpB8SxJgvaWcRpzL_45UEMeta } from "/app/services/frontend/src/pages/invite-action/[id].vue?macro=true";
import { default as payout_45instructionD6AHblTWYotS9b5Vnd5I86KHg4U6tyMFRusTE8Kv9zEMeta } from "/app/services/frontend/src/pages/payout-instruction.vue?macro=true";
import { default as ambassadorzv6uUuKOCBakxifqbV9_45bekGhT3bEpzFoPLZK1fMiMsMeta } from "/app/services/frontend/src/pages/register/ambassador.vue?macro=true";
import { default as sso_45finish7yVwylugkQPUvzoKnbcKS4In087bnB2O1Z_45sXtlbXtIMeta } from "/app/services/frontend/src/pages/vodafone/sso-finish.vue?macro=true";
import { default as terms_45and_45conditionsNu9_WxxRy_gu5N0_45Od7Wr_45yu1M8kQ_459_45ibz2A08gTe0Meta } from "/app/services/frontend/src/pages/terms-and-conditions.vue?macro=true";
import { default as index9fqYsYyChZm2KwAdj4HJK2dAy2BTFoGHw9g4ixubz34Meta } from "/app/services/frontend/src/pages/change-password/index.vue?macro=true";
import { default as _91id_93dX9EldzXGWvi2zcSt4iF6MVn0SZN08CewP23OzCWTBUMeta } from "/app/services/frontend/src/pages/sms-contract-sign/[id].vue?macro=true";
import { default as _91_91number_93_93qoy3YnT_bBqla0VoarG1n5sgZ0LZVEHO7mqudE8B7uIMeta } from "/app/services/frontend/src/pages/delivery-note/[[number]].vue?macro=true";
import { default as indexN96eMyeCwmzeZ6QeWeZH939igFS0hFLS_y3UerxsUBcMeta } from "/app/services/frontend/src/pages/forgotten-password/index.vue?macro=true";
import { default as _91id_93hnCn3bvYMsJku9lFWhJhY5wMRGsAGtqE0sFwXxZqdfoMeta } from "/app/services/frontend/src/pages/paper-contract-sign/[id].vue?macro=true";
import { default as _91token_93jle9SwDTDtq3mTTLxkkHvXIZkTnmZg5fOMsC1ZhnL34Meta } from "/app/services/frontend/src/pages/change-password/[uid]/[token].vue?macro=true";
import { default as _91token_938TBfWPi6hPJcmS2XwUbP9OOvgAOIoJ7BT_45gRY5L6HMgMeta } from "/app/services/frontend/src/pages/set-new-password/[uid]/[token].vue?macro=true";
import { default as indexeq4aGnxu4ZA6y4ygKmS_CtBhz3rGvClmnkavCleWoEEMeta } from "/app/services/frontend/src/pages/bank-account-verification/index.vue?macro=true";
import { default as _91token_93M9du3C2bl6J8NyKPTG8hKNwWEZ2VQSLMXFnTschPAlUMeta } from "/app/services/frontend/src/pages/forgotten-password/[uid]/[token].vue?macro=true";
import { default as _91registration_number_93ShbQ3QyGMlRIyK3XnpkUrrIlfSwdYGtGO0futocCsXUMeta } from "/app/services/frontend/src/pages/buyout-on-store/[registration_number].vue?macro=true";
import { default as _91_91quality_93_930ieLQ5q6nWjCjLyhGiZdDum19KOwE9LqF8UkVCTBu2AMeta } from "/app/services/frontend/src/pages/buyout/[[type]]/[[manufacturer]]/[[meta_master]]/[[variant]]/[[color]]/[[quality]].vue?macro=true";
import { default as _91_91quality_93_93Hl0GeBYldfUauDr1ZAgZIWmzexsE_45FwDXCcpMsxOwrQMeta } from "/app/services/frontend/src/pages/[bonus_partner]/buyout/[[new_device_type]]/[[new_meta_master]]/[[new_variant]]/[[new_color]]/[[type]]/[[manufacturer]]/[[meta_master]]/[[variant]]/[[color]]/[[quality]].vue?macro=true";
import { default as component_45stubqJMj5rU1nq3O9flBUOdJn6VrZy3B3oJno_hZB5jdn7IMeta } from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@9.23.0_jiti@2.4_79fe5d09dd4163cece4df3e077d2b5a4/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubqJMj5rU1nq3O9flBUOdJn6VrZy3B3oJno_hZB5jdn7I } from "/app/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.17_db0@0.3.1_eslint@9.23.0_jiti@2.4_79fe5d09dd4163cece4df3e077d2b5a4/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index___en",
    path: "/en",
    meta: indexf92vMeFVnFTK_1_45UvP6Slh90iB6qkk4KaiMHMkia2rYMeta || {},
    component: () => import("/app/services/frontend/src/pages/index.vue")
  },
  {
    name: "index___cs",
    path: "/",
    meta: indexf92vMeFVnFTK_1_45UvP6Slh90iB6qkk4KaiMHMkia2rYMeta || {},
    component: () => import("/app/services/frontend/src/pages/index.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    meta: loginoH5I5W90BlZYOUkT4FT7hkbxqERVQjfVJjl5gAu10r0Meta || {},
    component: () => import("/app/services/frontend/src/pages/login.vue")
  },
  {
    name: "login___cs",
    path: "/prihlaseni",
    meta: loginoH5I5W90BlZYOUkT4FT7hkbxqERVQjfVJjl5gAu10r0Meta || {},
    component: () => import("/app/services/frontend/src/pages/login.vue")
  },
  {
    name: "bonuses___en",
    path: "/en/bonuses",
    meta: bonusesN5dhYisSXzsRQrfoIMkksDdpg8u_45t0Xpc7MacdeMkDcMeta || {},
    component: () => import("/app/services/frontend/src/pages/bonuses.vue")
  },
  {
    name: "bonuses___cs",
    path: "/bonusy",
    meta: bonusesN5dhYisSXzsRQrfoIMkksDdpg8u_45t0Xpc7MacdeMkDcMeta || {},
    component: () => import("/app/services/frontend/src/pages/bonuses.vue")
  },
  {
    name: "contact___en",
    path: "/en/contact",
    meta: contactcpJvjKWwzPbSQ17PfIS13tbtG4y5lxQvH_451_45PbCwVAoMeta || {},
    component: () => import("/app/services/frontend/src/pages/contact.vue")
  },
  {
    name: "contact___cs",
    path: "/kontakt",
    meta: contactcpJvjKWwzPbSQ17PfIS13tbtG4y5lxQvH_451_45PbCwVAoMeta || {},
    component: () => import("/app/services/frontend/src/pages/contact.vue")
  },
  {
    name: "cookies___en",
    path: "/en/privacy-policy/cookies",
    meta: cookiesHOFhRshqKgtPUXYqxWphfGaK9sXhqX3mt_OWYYzFcbkMeta || {},
    component: () => import("/app/services/frontend/src/pages/cookies.vue")
  },
  {
    name: "cookies___cs",
    path: "/ochrana-osobnich-udaju/cookies",
    meta: cookiesHOFhRshqKgtPUXYqxWphfGaK9sXhqX3mt_OWYYzFcbkMeta || {},
    component: () => import("/app/services/frontend/src/pages/cookies.vue")
  },
  {
    name: "dpd-map___en",
    path: "/en/dpd",
    meta: dpd_45mapHEWxtX640C0eI5sV55u4hwEdUforPHIDVVCdTVtWj6IMeta || {},
    component: () => import("/app/services/frontend/src/pages/dpd-map.vue")
  },
  {
    name: "dpd-map___cs",
    path: "/dpd",
    meta: dpd_45mapHEWxtX640C0eI5sV55u4hwEdUforPHIDVVCdTVtWj6IMeta || {},
    component: () => import("/app/services/frontend/src/pages/dpd-map.vue")
  },
  {
    name: "premium___en",
    path: "/en/premium-partner",
    meta: premiumgyVwM9CoZOb3Z4yCanB4EEVfrUNS5Y9WMrh4FVqithwMeta || {},
    component: () => import("/app/services/frontend/src/pages/premium.vue")
  },
  {
    name: "premium___cs",
    path: "/premium-partner",
    meta: premiumgyVwM9CoZOb3Z4yCanB4EEVfrUNS5Y9WMrh4FVqithwMeta || {},
    component: () => import("/app/services/frontend/src/pages/premium.vue")
  },
  {
    name: profileNcPNWF1B8QDTQtkyb28wuG1PIY1HX_fCkeLnjVimR_YMeta?.name,
    path: "/en/profile",
    meta: profileNcPNWF1B8QDTQtkyb28wuG1PIY1HX_fCkeLnjVimR_YMeta || {},
    component: () => import("/app/services/frontend/src/pages/profile.vue"),
    children: [
  {
    name: "profile___en",
    path: "",
    meta: indexIqDLR_455DM27C7SQc4zqHAOI72iQet6FfkwX7W8UfT90Meta || {},
    component: () => import("/app/services/frontend/src/pages/profile/index.vue")
  },
  {
    name: "profile-bonuses___en",
    path: "bonuses",
    meta: bonusesBXypkuLRyCKuvmWGpB5HBXDh7_MiYRiQWeOb9ep4G_45gMeta || {},
    component: () => import("/app/services/frontend/src/pages/profile/bonuses.vue")
  },
  {
    name: "profile-rewards___en",
    path: "rewards",
    meta: rewards3HEI4LysQwBYPp_45iWSA7kd_45AlOxdcdmvYyN8pnSY_45SEMeta || {},
    component: () => import("/app/services/frontend/src/pages/profile/rewards.vue")
  },
  {
    name: "profile-messages___en",
    path: "messages",
    component: () => import("/app/services/frontend/src/pages/profile/messages.vue")
  },
  {
    name: "profile-dashboard___en",
    path: "dashboard",
    component: () => import("/app/services/frontend/src/pages/profile/dashboard.vue")
  },
  {
    name: "profile-education___en",
    path: "education",
    component: () => import("/app/services/frontend/src/pages/profile/education.vue")
  },
  {
    name: "profile-my-buyouts___en",
    path: "buyouts",
    meta: my_45buyoutse4FvltYQpjuk_45i68bQMOlN1lRliKYumAlInp8Y3SytQMeta || {},
    component: () => import("/app/services/frontend/src/pages/profile/my-buyouts.vue")
  },
  {
    name: "profile-tariff-list___en",
    path: "tariff-list",
    meta: tariff_45listDtkE_45oKSQI_45Igb7j6TwTtq1_45y57bKNVKqlp01vTBdPoMeta || {},
    component: () => import("/app/services/frontend/src/pages/profile/tariff-list.vue")
  },
  {
    name: "profile-notifications___en",
    path: "notifications",
    component: () => import("/app/services/frontend/src/pages/profile/notifications.vue")
  },
  {
    name: "profile-personal-info___en",
    path: "personal-info",
    meta: personal_45info7XeRLV7UocYW81HsiXOVuIyKna3ZmKbcZ39oRt0dfLsMeta || {},
    component: () => import("/app/services/frontend/src/pages/profile/personal-info.vue")
  },
  {
    name: "profile-delivery-notes___en",
    path: "delivery-notes",
    component: () => import("/app/services/frontend/src/pages/profile/delivery-notes.vue")
  },
  {
    name: "profile-my-buyouts-on-store___en",
    path: "taken-over-devices",
    component: () => import("/app/services/frontend/src/pages/profile/my-buyouts-on-store.vue")
  },
  {
    name: "profile-buyout-on-store-detail-registration_number___en",
    path: "taken-over-device-detail/:registration_number()",
    meta: _91registration_number_93MxZNHl6bF5HIEec_45LjErHt4KNGoc_AnPB2G2AjdB4iwMeta || {},
    component: () => import("/app/services/frontend/src/pages/profile/buyout-on-store-detail/[registration_number].vue")
  }
]
  },
  {
    name: profileNcPNWF1B8QDTQtkyb28wuG1PIY1HX_fCkeLnjVimR_YMeta?.name,
    path: "/profil",
    meta: profileNcPNWF1B8QDTQtkyb28wuG1PIY1HX_fCkeLnjVimR_YMeta || {},
    component: () => import("/app/services/frontend/src/pages/profile.vue"),
    children: [
  {
    name: "profile___cs",
    path: "",
    meta: indexIqDLR_455DM27C7SQc4zqHAOI72iQet6FfkwX7W8UfT90Meta || {},
    component: () => import("/app/services/frontend/src/pages/profile/index.vue")
  },
  {
    name: "profile-bonuses___cs",
    path: "bonusy",
    meta: bonusesBXypkuLRyCKuvmWGpB5HBXDh7_MiYRiQWeOb9ep4G_45gMeta || {},
    component: () => import("/app/services/frontend/src/pages/profile/bonuses.vue")
  },
  {
    name: "profile-rewards___cs",
    path: "odmeny",
    meta: rewards3HEI4LysQwBYPp_45iWSA7kd_45AlOxdcdmvYyN8pnSY_45SEMeta || {},
    component: () => import("/app/services/frontend/src/pages/profile/rewards.vue")
  },
  {
    name: "profile-messages___cs",
    path: "zpravy",
    component: () => import("/app/services/frontend/src/pages/profile/messages.vue")
  },
  {
    name: "profile-dashboard___cs",
    path: "prehled",
    component: () => import("/app/services/frontend/src/pages/profile/dashboard.vue")
  },
  {
    name: "profile-education___cs",
    path: "vzdelavani",
    component: () => import("/app/services/frontend/src/pages/profile/education.vue")
  },
  {
    name: "profile-my-buyouts___cs",
    path: "vykupy",
    meta: my_45buyoutse4FvltYQpjuk_45i68bQMOlN1lRliKYumAlInp8Y3SytQMeta || {},
    component: () => import("/app/services/frontend/src/pages/profile/my-buyouts.vue")
  },
  {
    name: "profile-tariff-list___cs",
    path: "sazebnik",
    meta: tariff_45listDtkE_45oKSQI_45Igb7j6TwTtq1_45y57bKNVKqlp01vTBdPoMeta || {},
    component: () => import("/app/services/frontend/src/pages/profile/tariff-list.vue")
  },
  {
    name: "profile-notifications___cs",
    path: "upozorneni",
    component: () => import("/app/services/frontend/src/pages/profile/notifications.vue")
  },
  {
    name: "profile-personal-info___cs",
    path: "osobni-udaje",
    meta: personal_45info7XeRLV7UocYW81HsiXOVuIyKna3ZmKbcZ39oRt0dfLsMeta || {},
    component: () => import("/app/services/frontend/src/pages/profile/personal-info.vue")
  },
  {
    name: "profile-delivery-notes___cs",
    path: "dodaci-listy",
    component: () => import("/app/services/frontend/src/pages/profile/delivery-notes.vue")
  },
  {
    name: "profile-my-buyouts-on-store___cs",
    path: "prevzate-pristroje",
    component: () => import("/app/services/frontend/src/pages/profile/my-buyouts-on-store.vue")
  },
  {
    name: "profile-buyout-on-store-detail-registration_number___cs",
    path: "prevzaty-pristroj-detail/:registration_number()",
    meta: _91registration_number_93MxZNHl6bF5HIEec_45LjErHt4KNGoc_AnPB2G2AjdB4iwMeta || {},
    component: () => import("/app/services/frontend/src/pages/profile/buyout-on-store-detail/[registration_number].vue")
  }
]
  },
  {
    name: _91partner_93jKXMi6lyFWp28rl5U9C7Cs8G6GmLRctT7JkjkR2pD2MMeta?.name,
    path: "/en/:partner()",
    meta: _91partner_93jKXMi6lyFWp28rl5U9C7Cs8G6GmLRctT7JkjkR2pD2MMeta || {},
    component: () => import("/app/services/frontend/src/pages/[partner].vue"),
    children: [
  {
    name: "partner-_sso___en",
    path: "_sso",
    meta: _ssoRFWmB3AGKIsn2wtv1EaPyhDZjqwRnOAMVMsbRPLKmUIMeta || {},
    component: () => import("/app/services/frontend/src/pages/[partner]/_sso.vue")
  },
  {
    name: "partner-base___en",
    path: "base",
    meta: based_457mGQGsgA5KkbiINyIKb78Wibpiy1dxHwWqrtPAIosMeta || {},
    component: () => import("/app/services/frontend/src/pages/[partner]/base.vue")
  },
  {
    name: "partner___en",
    path: "",
    meta: index2XbkPhgmoRlPhmLSqaP_GFgOHTYqoM2LUMjjDGPUd38Meta || {},
    component: () => import("/app/services/frontend/src/pages/[partner]/index.vue")
  },
  {
    name: "partner-xiaomi___en",
    path: "xiaomi",
    component: () => import("/app/services/frontend/src/pages/[partner]/xiaomi.vue")
  },
  {
    name: "partner-bonuses___en",
    path: "bonuses",
    meta: bonuseseXRhkPSG76_pgahio45iW0V9gEwMh1X5S_XTC0AE_45SYMeta || {},
    component: () => import("/app/services/frontend/src/pages/[partner]/bonuses.vue")
  },
  {
    name: "partner-contact___en",
    path: "contact",
    meta: contactueaeoWxn4EtS_45uAPC9t_45C8EYzxLZYfLtvagD0A8_45M00Meta || {},
    component: () => import("/app/services/frontend/src/pages/[partner]/contact.vue")
  },
  {
    name: "partner-samsung___en",
    path: "samsung",
    component: () => import("/app/services/frontend/src/pages/[partner]/samsung.vue")
  },
  {
    name: "partner-partners___en",
    path: "partners",
    meta: partnersGVMHDqd6BvRHu6mg2CF3vqAEWomvZDZsFGw2WGIzZVYMeta || {},
    component: () => import("/app/services/frontend/src/pages/[partner]/partners.vue")
  },
  {
    name: "partner-_sso-finish___en",
    path: "_sso-finish",
    meta: _sso_45finish2IKm5Etr8JDXCOSbnZGkPxS44jRyK9LGWcVq79Qyk8IMeta || {},
    component: () => import("/app/services/frontend/src/pages/[partner]/_sso-finish.vue")
  },
  {
    name: "partner-samsung-privacy-policy___en",
    path: "samsung-privacy-policy",
    meta: samsung_45privacy_45policyo8Udr_45vdwSAUrPCTlINW_UCc9_45kcfIdCxB41naTwIEMMeta || {},
    component: () => import("/app/services/frontend/src/pages/[partner]/samsung-privacy-policy.vue")
  }
]
  },
  {
    name: _91partner_93jKXMi6lyFWp28rl5U9C7Cs8G6GmLRctT7JkjkR2pD2MMeta?.name,
    path: "/:partner()",
    meta: _91partner_93jKXMi6lyFWp28rl5U9C7Cs8G6GmLRctT7JkjkR2pD2MMeta || {},
    component: () => import("/app/services/frontend/src/pages/[partner].vue"),
    children: [
  {
    name: "partner-_sso___cs",
    path: "_sso",
    meta: _ssoRFWmB3AGKIsn2wtv1EaPyhDZjqwRnOAMVMsbRPLKmUIMeta || {},
    component: () => import("/app/services/frontend/src/pages/[partner]/_sso.vue")
  },
  {
    name: "partner-base___cs",
    path: "base",
    meta: based_457mGQGsgA5KkbiINyIKb78Wibpiy1dxHwWqrtPAIosMeta || {},
    component: () => import("/app/services/frontend/src/pages/[partner]/base.vue")
  },
  {
    name: "partner___cs",
    path: "",
    meta: index2XbkPhgmoRlPhmLSqaP_GFgOHTYqoM2LUMjjDGPUd38Meta || {},
    component: () => import("/app/services/frontend/src/pages/[partner]/index.vue")
  },
  {
    name: "partner-xiaomi___cs",
    path: "xiaomi",
    component: () => import("/app/services/frontend/src/pages/[partner]/xiaomi.vue")
  },
  {
    name: "partner-bonuses___cs",
    path: "bonusy",
    meta: bonuseseXRhkPSG76_pgahio45iW0V9gEwMh1X5S_XTC0AE_45SYMeta || {},
    component: () => import("/app/services/frontend/src/pages/[partner]/bonuses.vue")
  },
  {
    name: "partner-contact___cs",
    path: "kontakt",
    meta: contactueaeoWxn4EtS_45uAPC9t_45C8EYzxLZYfLtvagD0A8_45M00Meta || {},
    component: () => import("/app/services/frontend/src/pages/[partner]/contact.vue")
  },
  {
    name: "partner-samsung___cs",
    path: "samsung",
    component: () => import("/app/services/frontend/src/pages/[partner]/samsung.vue")
  },
  {
    name: "partner-partners___cs",
    path: "partneri",
    meta: partnersGVMHDqd6BvRHu6mg2CF3vqAEWomvZDZsFGw2WGIzZVYMeta || {},
    component: () => import("/app/services/frontend/src/pages/[partner]/partners.vue")
  },
  {
    name: "partner-_sso-finish___cs",
    path: "_sso-finish",
    meta: _sso_45finish2IKm5Etr8JDXCOSbnZGkPxS44jRyK9LGWcVq79Qyk8IMeta || {},
    component: () => import("/app/services/frontend/src/pages/[partner]/_sso-finish.vue")
  },
  {
    name: "partner-samsung-privacy-policy___cs",
    path: "informace-o-zpracovani-osobnich-udaju-samsung",
    meta: samsung_45privacy_45policyo8Udr_45vdwSAUrPCTlINW_UCc9_45kcfIdCxB41naTwIEMMeta || {},
    component: () => import("/app/services/frontend/src/pages/[partner]/samsung-privacy-policy.vue")
  }
]
  },
  {
    name: "questions___en",
    path: "/en/questions",
    meta: questionsZCKMf13H0_459s5Z1C27lNAB2AHAb_T7tbZeXWIEIpfwcMeta || {},
    component: () => import("/app/services/frontend/src/pages/questions.vue")
  },
  {
    name: "questions___cs",
    path: "/dotazy",
    meta: questionsZCKMf13H0_459s5Z1C27lNAB2AHAb_T7tbZeXWIEIpfwcMeta || {},
    component: () => import("/app/services/frontend/src/pages/questions.vue")
  },
  {
    name: "vodafone-sso",
    path: "/vodafone/sso",
    meta: ssoKpmUZmuJqyb7MXx_ntVGqgvadNKo26ZqT4EmHwiPsG4Meta || {},
    component: () => import("/app/services/frontend/src/pages/vodafone/sso.vue")
  },
  {
    name: "privacy-policy___en",
    path: "/en/privacy-policy",
    meta: privacy_45policyoidUwg0kMvh4f6h7qGZUfw_8jq2JNb9tZ9WfDRbMfvEMeta || {},
    component: () => import("/app/services/frontend/src/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___cs",
    path: "/ochrana-osobnich-udaju",
    meta: privacy_45policyoidUwg0kMvh4f6h7qGZUfw_8jq2JNb9tZ9WfDRbMfvEMeta || {},
    component: () => import("/app/services/frontend/src/pages/privacy-policy.vue")
  },
  {
    name: "sustainability___en",
    path: "/en/sustainability",
    meta: sustainabilityYoUDF_45MUouPs9ZhPPvaPYas8cD16IMpWC6gnxmshBXgMeta || {},
    component: () => import("/app/services/frontend/src/pages/sustainability.vue"),
    children: [
  {
    name: "sustainability-box___en",
    path: "box",
    component: () => import("/app/services/frontend/src/pages/sustainability/box.vue")
  },
  {
    name: "sustainability-hiw___en",
    path: "hiw",
    component: () => import("/app/services/frontend/src/pages/sustainability/hiw.vue")
  },
  {
    name: "sustainability-hwh___en",
    path: "hwh",
    component: () => import("/app/services/frontend/src/pages/sustainability/hwh.vue")
  },
  {
    name: "sustainability-hero___en",
    path: "hero",
    component: () => import("/app/services/frontend/src/pages/sustainability/hero.vue")
  },
  {
    name: "sustainability-info___en",
    path: "info",
    component: () => import("/app/services/frontend/src/pages/sustainability/info.vue")
  },
  {
    name: "sustainability-keyAreas___en",
    path: "keyAreas",
    component: () => import("/app/services/frontend/src/pages/sustainability/keyAreas.vue")
  }
]
  },
  {
    name: "sustainability___cs",
    path: "/udrzitelnost",
    meta: sustainabilityYoUDF_45MUouPs9ZhPPvaPYas8cD16IMpWC6gnxmshBXgMeta || {},
    component: () => import("/app/services/frontend/src/pages/sustainability.vue"),
    children: [
  {
    name: "sustainability-box___cs",
    path: "box",
    component: () => import("/app/services/frontend/src/pages/sustainability/box.vue")
  },
  {
    name: "sustainability-hiw___cs",
    path: "hiw",
    component: () => import("/app/services/frontend/src/pages/sustainability/hiw.vue")
  },
  {
    name: "sustainability-hwh___cs",
    path: "hwh",
    component: () => import("/app/services/frontend/src/pages/sustainability/hwh.vue")
  },
  {
    name: "sustainability-hero___cs",
    path: "hero",
    component: () => import("/app/services/frontend/src/pages/sustainability/hero.vue")
  },
  {
    name: "sustainability-info___cs",
    path: "info",
    component: () => import("/app/services/frontend/src/pages/sustainability/info.vue")
  },
  {
    name: "sustainability-keyAreas___cs",
    path: "keyAreas",
    component: () => import("/app/services/frontend/src/pages/sustainability/keyAreas.vue")
  }
]
  },
  {
    name: profile_45premiumu7OSnMtcKpckSgkr5TQttqMQsbHSRO4BVx_mN4P7wmkMeta?.name,
    path: "/en/profile-premium",
    meta: profile_45premiumu7OSnMtcKpckSgkr5TQttqMQsbHSRO4BVx_mN4P7wmkMeta || {},
    component: () => import("/app/services/frontend/src/pages/profile-premium.vue"),
    children: [
  {
    name: "profile-premium-api___en",
    path: "api",
    component: () => import("/app/services/frontend/src/pages/profile-premium/api.vue")
  },
  {
    name: "profile-premium___en",
    path: "",
    meta: indexb9VBrrKf7uX0gVz_45iZXiYN0ksNON6jNozxBmUZWiWnsMeta || {},
    component: () => import("/app/services/frontend/src/pages/profile-premium/index.vue")
  },
  {
    name: "profile-premium-exports___en",
    path: "exports",
    component: () => import("/app/services/frontend/src/pages/profile-premium/exports.vue")
  },
  {
    name: "profile-premium-dashboard___en",
    path: "dashboard",
    component: () => import("/app/services/frontend/src/pages/profile-premium/dashboard.vue")
  },
  {
    name: "profile-premium-education___en",
    path: "education",
    component: () => import("/app/services/frontend/src/pages/profile-premium/education.vue")
  },
  {
    name: "profile-premium-ambassadors___en",
    path: "ambassadors",
    component: () => import("/app/services/frontend/src/pages/profile-premium/ambassadors.vue")
  },
  {
    name: "profile-premium-company-data___en",
    path: "company-data",
    meta: company_45data7oRrga_DYpsCLN7Av5_4542pUmdEXhOafSslzIndrTOhMMeta || {},
    component: () => import("/app/services/frontend/src/pages/profile-premium/company-data.vue")
  },
  {
    name: "profile-premium-notifications___en",
    path: "notification",
    component: () => import("/app/services/frontend/src/pages/profile-premium/notifications.vue")
  },
  {
    name: "profile-premium-import-ambassadors___en",
    path: "import-ambassadors",
    meta: import_45ambassadorsXHqblMSzkYBIq1ZaxYi5diowgOjiVmXbl8tarAt5EvsMeta || {},
    component: () => import("/app/services/frontend/src/pages/profile-premium/import-ambassadors.vue")
  }
]
  },
  {
    name: profile_45premiumu7OSnMtcKpckSgkr5TQttqMQsbHSRO4BVx_mN4P7wmkMeta?.name,
    path: "/profil-premium",
    meta: profile_45premiumu7OSnMtcKpckSgkr5TQttqMQsbHSRO4BVx_mN4P7wmkMeta || {},
    component: () => import("/app/services/frontend/src/pages/profile-premium.vue"),
    children: [
  {
    name: "profile-premium-api___cs",
    path: "api",
    component: () => import("/app/services/frontend/src/pages/profile-premium/api.vue")
  },
  {
    name: "profile-premium___cs",
    path: "",
    meta: indexb9VBrrKf7uX0gVz_45iZXiYN0ksNON6jNozxBmUZWiWnsMeta || {},
    component: () => import("/app/services/frontend/src/pages/profile-premium/index.vue")
  },
  {
    name: "profile-premium-exports___cs",
    path: "exporty",
    component: () => import("/app/services/frontend/src/pages/profile-premium/exports.vue")
  },
  {
    name: "profile-premium-dashboard___cs",
    path: "prehled",
    component: () => import("/app/services/frontend/src/pages/profile-premium/dashboard.vue")
  },
  {
    name: "profile-premium-education___cs",
    path: "vzdelavani",
    component: () => import("/app/services/frontend/src/pages/profile-premium/education.vue")
  },
  {
    name: "profile-premium-ambassadors___cs",
    path: "ambasadori",
    component: () => import("/app/services/frontend/src/pages/profile-premium/ambassadors.vue")
  },
  {
    name: "profile-premium-company-data___cs",
    path: "firemni-udaje",
    meta: company_45data7oRrga_DYpsCLN7Av5_4542pUmdEXhOafSslzIndrTOhMMeta || {},
    component: () => import("/app/services/frontend/src/pages/profile-premium/company-data.vue")
  },
  {
    name: "profile-premium-notifications___cs",
    path: "upozorneni",
    component: () => import("/app/services/frontend/src/pages/profile-premium/notifications.vue")
  },
  {
    name: "profile-premium-import-ambassadors___cs",
    path: "pridani-ambasadoru",
    meta: import_45ambassadorsXHqblMSzkYBIq1ZaxYi5diowgOjiVmXbl8tarAt5EvsMeta || {},
    component: () => import("/app/services/frontend/src/pages/profile-premium/import-ambassadors.vue")
  }
]
  },
  {
    name: "register-partner___en",
    path: "/en/register/partner",
    meta: partnerfN41GEpoSM3NqcfsGg0iHW8cNQn_45OYAgiQI6zWOFPJUMeta || {},
    component: () => import("/app/services/frontend/src/pages/register/partner.vue")
  },
  {
    name: "register-partner___cs",
    path: "/registrace/partner",
    meta: partnerfN41GEpoSM3NqcfsGg0iHW8cNQn_45OYAgiQI6zWOFPJUMeta || {},
    component: () => import("/app/services/frontend/src/pages/register/partner.vue")
  },
  {
    name: "buyout-detail-id___en",
    path: "/en/buyout-detail/:id()",
    meta: _91id_933eia60zE2NBizOSM_yYItT9M53xUO07hA21lfSX_45fmMMeta || {},
    component: () => import("/app/services/frontend/src/pages/buyout-detail/[id].vue")
  },
  {
    name: "buyout-detail-id___cs",
    path: "/detail-vykupu/:id()",
    meta: _91id_933eia60zE2NBizOSM_yYItT9M53xUO07hA21lfSX_45fmMMeta || {},
    component: () => import("/app/services/frontend/src/pages/buyout-detail/[id].vue")
  },
  {
    name: "invite-action-id___en",
    path: "/en/invite-action/:id()",
    meta: _91id_93rm6vJ1cSqSi5S_fyurZ5J5K4RpB8SxJgvaWcRpzL_45UEMeta || {},
    component: () => import("/app/services/frontend/src/pages/invite-action/[id].vue")
  },
  {
    name: "invite-action-id___cs",
    path: "/akce-pozvanky/:id()",
    meta: _91id_93rm6vJ1cSqSi5S_fyurZ5J5K4RpB8SxJgvaWcRpzL_45UEMeta || {},
    component: () => import("/app/services/frontend/src/pages/invite-action/[id].vue")
  },
  {
    name: "payout-instruction___en",
    path: "/en/payout-instruction",
    meta: payout_45instructionD6AHblTWYotS9b5Vnd5I86KHg4U6tyMFRusTE8Kv9zEMeta || {},
    component: () => import("/app/services/frontend/src/pages/payout-instruction.vue")
  },
  {
    name: "payout-instruction___cs",
    path: "/pokyn-k-vyplate",
    meta: payout_45instructionD6AHblTWYotS9b5Vnd5I86KHg4U6tyMFRusTE8Kv9zEMeta || {},
    component: () => import("/app/services/frontend/src/pages/payout-instruction.vue")
  },
  {
    name: "register-ambassador___en",
    path: "/en/register/customer",
    meta: ambassadorzv6uUuKOCBakxifqbV9_45bekGhT3bEpzFoPLZK1fMiMsMeta || {},
    component: () => import("/app/services/frontend/src/pages/register/ambassador.vue")
  },
  {
    name: "register-ambassador___cs",
    path: "/registrace/zakaznik",
    meta: ambassadorzv6uUuKOCBakxifqbV9_45bekGhT3bEpzFoPLZK1fMiMsMeta || {},
    component: () => import("/app/services/frontend/src/pages/register/ambassador.vue")
  },
  {
    name: "vodafone-sso-finish",
    path: "/vodafone/sso-finish",
    meta: sso_45finish7yVwylugkQPUvzoKnbcKS4In087bnB2O1Z_45sXtlbXtIMeta || {},
    component: () => import("/app/services/frontend/src/pages/vodafone/sso-finish.vue")
  },
  {
    name: "terms-and-conditions___en",
    path: "/en/terms-and-conditions",
    meta: terms_45and_45conditionsNu9_WxxRy_gu5N0_45Od7Wr_45yu1M8kQ_459_45ibz2A08gTe0Meta || {},
    component: () => import("/app/services/frontend/src/pages/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___cs",
    path: "/obchodni-podminky",
    meta: terms_45and_45conditionsNu9_WxxRy_gu5N0_45Od7Wr_45yu1M8kQ_459_45ibz2A08gTe0Meta || {},
    component: () => import("/app/services/frontend/src/pages/terms-and-conditions.vue")
  },
  {
    name: "change-password___en",
    path: "/en/change-password",
    meta: index9fqYsYyChZm2KwAdj4HJK2dAy2BTFoGHw9g4ixubz34Meta || {},
    component: () => import("/app/services/frontend/src/pages/change-password/index.vue")
  },
  {
    name: "change-password___cs",
    path: "/zmena-hesla",
    meta: index9fqYsYyChZm2KwAdj4HJK2dAy2BTFoGHw9g4ixubz34Meta || {},
    component: () => import("/app/services/frontend/src/pages/change-password/index.vue")
  },
  {
    name: "sms-contract-sign-id___en",
    path: "/en/sms-contract-sign/:id()",
    meta: _91id_93dX9EldzXGWvi2zcSt4iF6MVn0SZN08CewP23OzCWTBUMeta || {},
    component: () => import("/app/services/frontend/src/pages/sms-contract-sign/[id].vue")
  },
  {
    name: "sms-contract-sign-id___cs",
    path: "/sms-podepsani-smlouvy/:id()",
    meta: _91id_93dX9EldzXGWvi2zcSt4iF6MVn0SZN08CewP23OzCWTBUMeta || {},
    component: () => import("/app/services/frontend/src/pages/sms-contract-sign/[id].vue")
  },
  {
    name: "delivery-note-number___en",
    path: "/en/delivery-note/:number?",
    component: () => import("/app/services/frontend/src/pages/delivery-note/[[number]].vue")
  },
  {
    name: "delivery-note-number___cs",
    path: "/dodaci-list/:number?",
    component: () => import("/app/services/frontend/src/pages/delivery-note/[[number]].vue")
  },
  {
    name: "forgotten-password___en",
    path: "/en/forgotten-password",
    meta: indexN96eMyeCwmzeZ6QeWeZH939igFS0hFLS_y3UerxsUBcMeta || {},
    component: () => import("/app/services/frontend/src/pages/forgotten-password/index.vue")
  },
  {
    name: "forgotten-password___cs",
    path: "/zapomenute-heslo",
    meta: indexN96eMyeCwmzeZ6QeWeZH939igFS0hFLS_y3UerxsUBcMeta || {},
    component: () => import("/app/services/frontend/src/pages/forgotten-password/index.vue")
  },
  {
    name: "paper-contract-sign-id___en",
    path: "/en/paper-contract-sign/:id()",
    meta: _91id_93hnCn3bvYMsJku9lFWhJhY5wMRGsAGtqE0sFwXxZqdfoMeta || {},
    component: () => import("/app/services/frontend/src/pages/paper-contract-sign/[id].vue")
  },
  {
    name: "paper-contract-sign-id___cs",
    path: "/papirove-podepsani-smlouvy/:id()",
    meta: _91id_93hnCn3bvYMsJku9lFWhJhY5wMRGsAGtqE0sFwXxZqdfoMeta || {},
    component: () => import("/app/services/frontend/src/pages/paper-contract-sign/[id].vue")
  },
  {
    name: "change-password-uid-token___en",
    path: "/en/change-password/:uid()/:token()",
    meta: _91token_93jle9SwDTDtq3mTTLxkkHvXIZkTnmZg5fOMsC1ZhnL34Meta || {},
    component: () => import("/app/services/frontend/src/pages/change-password/[uid]/[token].vue")
  },
  {
    name: "change-password-uid-token___cs",
    path: "/zmena-hesla/:uid()/:token()",
    meta: _91token_93jle9SwDTDtq3mTTLxkkHvXIZkTnmZg5fOMsC1ZhnL34Meta || {},
    component: () => import("/app/services/frontend/src/pages/change-password/[uid]/[token].vue")
  },
  {
    name: "set-new-password-uid-token___en",
    path: "/en/set-new-password/:uid()/:token()",
    meta: _91token_938TBfWPi6hPJcmS2XwUbP9OOvgAOIoJ7BT_45gRY5L6HMgMeta || {},
    component: () => import("/app/services/frontend/src/pages/set-new-password/[uid]/[token].vue")
  },
  {
    name: "set-new-password-uid-token___cs",
    path: "/nastaveni-noveho-hesla/:uid()/:token()",
    meta: _91token_938TBfWPi6hPJcmS2XwUbP9OOvgAOIoJ7BT_45gRY5L6HMgMeta || {},
    component: () => import("/app/services/frontend/src/pages/set-new-password/[uid]/[token].vue")
  },
  {
    name: "bank-account-verification___en",
    path: "/en/bank-account-verification",
    meta: indexeq4aGnxu4ZA6y4ygKmS_CtBhz3rGvClmnkavCleWoEEMeta || {},
    component: () => import("/app/services/frontend/src/pages/bank-account-verification/index.vue")
  },
  {
    name: "bank-account-verification___cs",
    path: "/overeni-bankovniho-uctu",
    meta: indexeq4aGnxu4ZA6y4ygKmS_CtBhz3rGvClmnkavCleWoEEMeta || {},
    component: () => import("/app/services/frontend/src/pages/bank-account-verification/index.vue")
  },
  {
    name: "forgotten-password-uid-token___en",
    path: "/en/forgotten-password/:uid()/:token()",
    meta: _91token_93M9du3C2bl6J8NyKPTG8hKNwWEZ2VQSLMXFnTschPAlUMeta || {},
    component: () => import("/app/services/frontend/src/pages/forgotten-password/[uid]/[token].vue")
  },
  {
    name: "forgotten-password-uid-token___cs",
    path: "/zapomenute-heslo/:uid()/:token()",
    meta: _91token_93M9du3C2bl6J8NyKPTG8hKNwWEZ2VQSLMXFnTschPAlUMeta || {},
    component: () => import("/app/services/frontend/src/pages/forgotten-password/[uid]/[token].vue")
  },
  {
    name: "buyout-on-store-registration_number___en",
    path: "/en/buyout-on-store/:registration_number()",
    component: () => import("/app/services/frontend/src/pages/buyout-on-store/[registration_number].vue")
  },
  {
    name: "buyout-on-store-registration_number___cs",
    path: "/vykup-na-pobocce/:registration_number()",
    component: () => import("/app/services/frontend/src/pages/buyout-on-store/[registration_number].vue")
  },
  {
    name: "buyout-type-manufacturer-meta_master-variant-color-quality___en",
    path: "/en/buyout/:type?/:manufacturer?/:meta_master?/:variant?/:color?/:quality?",
    meta: _91_91quality_93_930ieLQ5q6nWjCjLyhGiZdDum19KOwE9LqF8UkVCTBu2AMeta || {},
    component: () => import("/app/services/frontend/src/pages/buyout/[[type]]/[[manufacturer]]/[[meta_master]]/[[variant]]/[[color]]/[[quality]].vue")
  },
  {
    name: "buyout-type-manufacturer-meta_master-variant-color-quality___cs",
    path: "/vykup/:type?/:manufacturer?/:meta_master?/:variant?/:color?/:quality?",
    meta: _91_91quality_93_930ieLQ5q6nWjCjLyhGiZdDum19KOwE9LqF8UkVCTBu2AMeta || {},
    component: () => import("/app/services/frontend/src/pages/buyout/[[type]]/[[manufacturer]]/[[meta_master]]/[[variant]]/[[color]]/[[quality]].vue")
  },
  {
    name: "bonus_partner-buyout-new_device_type-new_meta_master-new_variant-new_color-type-manufacturer-meta_master-variant-color-quality___en",
    path: "/en/:bonus_partner()/buyout/:new_device_type?/:new_meta_master?/:new_variant?/:new_color?/:type?/:manufacturer?/:meta_master?/:variant?/:color?/:quality?",
    meta: _91_91quality_93_93Hl0GeBYldfUauDr1ZAgZIWmzexsE_45FwDXCcpMsxOwrQMeta || {},
    component: () => import("/app/services/frontend/src/pages/[bonus_partner]/buyout/[[new_device_type]]/[[new_meta_master]]/[[new_variant]]/[[new_color]]/[[type]]/[[manufacturer]]/[[meta_master]]/[[variant]]/[[color]]/[[quality]].vue")
  },
  {
    name: "bonus_partner-buyout-new_device_type-new_meta_master-new_variant-new_color-type-manufacturer-meta_master-variant-color-quality___cs",
    path: "/:bonus_partner()/vykup/:new_device_type?/:new_meta_master?/:new_variant?/:new_color?/:type?/:manufacturer?/:meta_master?/:variant?/:color?/:quality?",
    meta: _91_91quality_93_93Hl0GeBYldfUauDr1ZAgZIWmzexsE_45FwDXCcpMsxOwrQMeta || {},
    component: () => import("/app/services/frontend/src/pages/[bonus_partner]/buyout/[[new_device_type]]/[[new_meta_master]]/[[new_variant]]/[[new_color]]/[[type]]/[[manufacturer]]/[[meta_master]]/[[variant]]/[[color]]/[[quality]].vue")
  },
  {
    name: component_45stubqJMj5rU1nq3O9flBUOdJn6VrZy3B3oJno_hZB5jdn7IMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubqJMj5rU1nq3O9flBUOdJn6VrZy3B3oJno_hZB5jdn7I
  }
]